import React, { useState } from "react";
import QrReader from "react-qr-reader";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import toastr from "toastr";
import { useHistory } from "react-router-dom";
import { Row, Col } from "reactstrap"
import { getPublic } from "../../helpers/api_helper";
import "./index.scss";
let timeOutSearch;
const cameraOptions = [
  {
    label: "Camera Trước",
    value: "user",
  },
  {
    label: "Camera Sau",
    value: "environment",
  },
];

const ScanOder = () => {
  const history = useHistory();
  const [facingMode, setFacingMode] = useState("user");
  const handleScan = (data) => {
    if (data) {
      getPublic(`/bills/${data}`)
        .then((res) => {
          history.push({
            pathname: "oder-manager",
            state: {
              status: "INFO",
              idBill: res.data.id,
            },
          });
        })
        .catch(() => {
          toastr.error("Mã QR không chính xác");
        });
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  const handleSearch = (id) => {
    clearTimeout(timeOutSearch);
    timeOutSearch = setTimeout(() => {
      getPublic(`/bills/${id}`)
        .then((res) => {
          history.push({
            pathname: "oder-manager",
            state: {
              status: "INFO",
              idBill: res.data.id,
            },
          });
        })
        .catch(() => {
          toastr.error("Không tìm thấy đơn hàng");
        });
    }, 1000);
  };
  return (
    <div className="scan-oder">
      <Breadcrumbs title="Đơn hàng" breadcrumbItem="danh sách hàng" />
      <div className="scan-oder_content">
        <div className="scan-oder_selectedCamera">
          <Select
            placeholder="Camera Front"
            options={cameraOptions}
            defaultValue={cameraOptions[0]}
            onChange={(option) => setFacingMode(option.value)}
            classNamePrefix="select2-selection"
          />
        </div>
        <div className="scan-oder_camera">
          <Row className="justify-content-center">
            <Col md={4} lg={2} col={12}>
              <QrReader
              facingMode={facingMode}
              delay={1000}
              onError={handleError}
              onScan={handleScan}
            />
            </Col>
          </Row>
          
        </div>
        <div className="scan-oder_input">
          <p>Hoặc</p>
          <input
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Nhập mã đơn hàng"
          />
        </div>
      </div>
    </div>
  );
};

export default ScanOder;
