import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";
import { isMobile } from 'react-device-detect';

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import { toggleLeftmenu } from "../../store/actions"

const Navbar = (props) => {
  const user = JSON.parse(localStorage.getItem("authUser"));
  const isAdmin = user.role === 'ADMIN';
  const [dashboard, setdashboard] = useState(false);
  const [category, setCategory] = useState(false);
  const [product, setProduct] = useState(false);
  const [bill, setBill] = useState(false);
  const [ui, setui] = useState(false);
  const [app, setapp] = useState(false);
  const [email, setemail] = useState(false);
  const [task, settask] = useState(false);
  const [component, setcomponent] = useState(false);
  const [form, setform] = useState(false);
  const [table, settable] = useState(false);
  const [chart, setchart] = useState(false);
  const [icon, seticon] = useState(false);
  const [map, setmap] = useState(false);
  const [extra, setextra] = useState(false);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });
  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <nav
          className="navbar navbar-light navbar-expand-lg topnav-menu"
          id="navigation"
        >
          <Collapse
            isOpen={props.leftMenu}
            className="navbar-collapse"
            id="topnav-menu-content"
          >
            <ul className="navbar-nav">
              {isAdmin ? <>
                <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to="/dashboard"
                  onClick={() => {
                    props.toggleLeftmenu(!props.leftMenu)
                  }}
                >
                  {props.t("Thống kê truy cập")}{" "}
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to="/carousel"
                  onClick={() => {
                    props.toggleLeftmenu(!props.leftMenu)
                  }}
                >
                  {props.t("Carousel")}{" "}
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to="/category"
                  onClick={() => {
                    props.toggleLeftmenu(!props.leftMenu)
                  }}
                >
                  {props.t("Danh mục")}{" "}
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to={`/product${isMobile ? '-list-mobile' : ''}`}
                  onClick={() => {
                    props.toggleLeftmenu(!props.leftMenu)
                  }}
                >
                  {props.t("Sản phẩm")}{" "}
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to="/category-products"
                  onClick={() => {
                    props.toggleLeftmenu(!props.leftMenu)
                  }}
                >
                  Sắp xếp Nhanh
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to="/list-account"
                  onClick={() => {
                    props.toggleLeftmenu(!props.leftMenu)
                  }}
                >
                  Danh sách tài khoản
                </Link>
              </li>
              
              </> : <></>}
              <li className="nav-item dropdown">
                <Link
                  to="/"
                  onClick={e => {
                    e.preventDefault()
                    setui(!ui)
                  }}
                  className="nav-link dropdown-toggle arrow-none"
                >
                  {props.t("Đơn hàng")} <div className="arrow-down"></div>
                </Link>
                <div
                  style={{
                    minWidth: "200px",
                    width: "100%",
                  }}
                  className={classname(
                    "dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl",
                    { show: ui }
                  )}
                >
                  <Row>
                    <Col lg={12}>
                      <div>
                        <Link className="dropdown-item" to="/status-oder" onClick={() => {
                          props.toggleLeftmenu(!props.leftMenu)
                        }}>
                          Danh sách đơn hàng
                        </Link>
                        <Link className="dropdown-item" to="/oder-manager" onClick={() => {
                          props.toggleLeftmenu(!props.leftMenu);
                          window.location.href = '/oder-manager';
                        }}>
                          Tạo đơn hàng
                        </Link>
                        <Link className="dropdown-item" to="/list-detail-oder" onClick={() => {
                          props.toggleLeftmenu(!props.leftMenu)
                        }}>
                          Danh sách hàng
                        </Link>
                        <Link className="dropdown-item" to="/scan-oder" onClick={() => {
                          props.toggleLeftmenu(!props.leftMenu)
                        }}>
                          Tìm đơn hàng
                        </Link>
                        <Link className="dropdown-item" to="/list-temp-bill" onClick={() => {
                          props.toggleLeftmenu(!props.leftMenu)
                        }}>
                          Danh sách đơn hàng tạm
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link
                  to="/"
                  onClick={e => {
                    e.preventDefault()
                    setui(!ui)
                  }}
                  className="nav-link dropdown-toggle arrow-none"
                >
                  {props.t("Quản lý kho")} <div className="arrow-down"></div>
                </Link>
                <div
                  style={{
                    minWidth: "200px",
                    width: "100%",
                  }}
                  className={classname(
                    "dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl",
                    { show: ui }
                  )}
                >
                  <Row>
                    <Col lg={12}>
                      <div>
                        <Link className="dropdown-item" to="/scan-packing-inventory" onClick={() => {
                          props.toggleLeftmenu(!props.leftMenu)
                        }}>
                          Soạn hàng
                        </Link>
                        <Link className="dropdown-item" to="/scan-delivery-inventory" onClick={() => {
                          props.toggleLeftmenu(!props.leftMenu)
                        }}>
                          Kiểm hàng
                        </Link>
                        <Link className="dropdown-item" to="/scan-in-package-inventory" onClick={() => {
                          props.toggleLeftmenu(!props.leftMenu)
                        }}>
                          Đóng gói
                        </Link>
                        <Link className="dropdown-item" to="/scan-to-shipper-inventory" onClick={() => {
                          props.toggleLeftmenu(!props.leftMenu)
                        }}>
                          Giao hàng
                        </Link>
                        <Link className="dropdown-item" to="/scan-not-pay-inventory" onClick={() => {
                          props.toggleLeftmenu(!props.leftMenu)
                        }}>
                          Chưa thanh toán
                        </Link>

                        <Link className="dropdown-item" to="/scan-waiting-export-vat-inventory" onClick={() => {
                          props.toggleLeftmenu(!props.leftMenu)
                        }}>
                          Chờ xuất hoá đơn
                        </Link>
                        <Link className="dropdown-item" to="/list-waiting-export-vat-inventory" onClick={() => {
                          props.toggleLeftmenu(!props.leftMenu)
                        }}>
                          DS chờ xuất hoá đơn
                        </Link>
                        <Link className="dropdown-item" to="/scan-finished" onClick={() => {
                          props.toggleLeftmenu(!props.leftMenu)
                        }}>
                          Hoàn thành
                        </Link>
                        <Link className="dropdown-item" to="/get-scan-inventory" onClick={() => {
                          props.toggleLeftmenu(!props.leftMenu)
                        }}>
                          Quét tìm hàng
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </li>
              { isAdmin ?  <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to="/delivery-code"
                  onClick={() => {
                    props.toggleLeftmenu(!props.leftMenu)
                  }}
                >
                  Giá vận chuyển
                </Link>
              </li> : <></>}
            </ul>
          </Collapse>
        </nav>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, { toggleLeftmenu })(withTranslation()(Navbar))
);
