import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ListItemOder from "./list";
import { get } from "../../helpers/api_helper";
import "./index.scss";
import { isMobile } from 'react-device-detect';
import toastr from 'toastr';
import slugify from "slugify";
const LIMIT = 10;
const STATUS = {
  CREATED: "CREATED",
  CONFIRMED: "CONFIRMED",
  PREPARING: "PREPARING",
  CHECK_PACKAGE: "CHECK_PACKAGE",
  IN_PACKAGE: "IN_PACKAGE",
  DELIVERING: "DELIVERING",
  FINISHED: "FINISHED",
  WAIT_CUSTOMER_PICK_UP: "WAIT_CUSTOMER_PICK_UP",
  WAITING_PAY: "WAITING_PAY",
  WAITING_EXPORT_VAT: "WAITING_EXPORT_VAT",
  CANCELED: "CANCELED"
};


const STATUS_TITLE = {
  CREATED: "Vừa đặt",
  CONFIRMED: "Xác nhận",
  PREPARING: "Soạn hàng",
  CHECK_PACKAGE: "Kiểm hàng",
  IN_PACKAGE: "Đóng gói ",
  DELIVERING: "Giao hàng",
  WAIT_CUSTOMER_PICK_UP: "Khách đến lấy",
  WAITING_PAY: "Đợi thanh toán",
  WAITING_EXPORT_VAT: "Đợi xuất HĐ đỏ",
  FINISHED: "Hoàn thành",
  CANCELED: "Đơn bị huỷ",
};

let timeOutSearch;
let DEFAULT_STATUS_SHOW = localStorage.getItem("DEFAULT_STATUS_SHOW");
if (!DEFAULT_STATUS_SHOW || Object.keys(JSON.parse(DEFAULT_STATUS_SHOW)).find(e => !STATUS[e])) {
  DEFAULT_STATUS_SHOW = {
    CREATED: true,
    CONFIRMED: !isMobile,
    PREPARING: !isMobile,
    CHECK_PACKAGE: !isMobile,
    IN_PACKAGE: !isMobile,
    DELIVERING: false,
    FINISHED: false,
    WAIT_CUSTOMER_PICK_UP: false,
    WAITING_PAY: !isMobile,
    CANCELED: false,
    WAITING_EXPORT_VAT: false,

  }
} else {
  DEFAULT_STATUS_SHOW = JSON.parse(DEFAULT_STATUS_SHOW)
}

const ListOder = () => {
  const [list, setList] = useState({
    CREATED: [],
    CONFIRMED: [],
    PREPARING: [],
    CHECK_PACKAGE: [],
    IN_PACKAGE: [],
    DELIVERING: [],
    FINISHED: [],
    WAIT_CUSTOMER_PICK_UP: [],
    WAITING_PAY: [],
    WAITING_EXPORT_VAT: [],
    CANCELED: [],
  });

  const [offset, setOffset] = useState({
    FINISHED: 0,
    CANCELED: 0,
  });

  const [total, setTotal] = useState({
    FINISHED: 0,
    CANCELED: 0,
  });

  const [searchKeyPagi, setSearchKeyPagi] = useState({
    FINISHED: "",
    CANCELED: "",
  });
  const [searchKey, setSearchKey] = useState('');

  const [show, setShow] = useState({ ...DEFAULT_STATUS_SHOW });

  useEffect(() => {
    localStorage.setItem("DEFAULT_STATUS_SHOW", JSON.stringify(show))
  }, [show])

  const [showDropdown, setShowDropdown] = useState(false);

  const fetchAPI = (offset, limit, status, search) => {
    return get(
      `/bills/list?offset=${offset}&limit=${limit}&status=${status}${search ? `&search=${search}` : ""
      }`
    ).then((data) => {
      return data;
    });
  };

  const initPage = () => {
    Promise.all([
      get("/bills/kanban"),
      get(`/bills/list?offset=0&limit=${LIMIT}&status=FINISHED`),
      get(`/bills/list?offset=0&limit=${LIMIT}&status=CANCELED`),
    ]).then((res) => {
      setList({
        ...res[0],
        FINISHED: res[1].list,
        CANCELED: res[2].list,
      });
      setTotal({
        FINISHED: res[1].total,
        CANCELED: res[2].total,
      });
    });
  };

  useEffect(() => {
    initPage();
  }, []);

  const handleShowMoreFinished = (status) => {
    let newOffset;
    newOffset = offset.FINISHED + LIMIT;
    fetchAPI(newOffset, LIMIT, status, searchKeyPagi.FINISHED).then((data) => {
      setList({ ...list, FINISHED: [...list.FINISHED, ...data.list] });
      setOffset({
        ...offset,
        FINISHED: data.offset
      });
    });
  };

  const handleShowMoreCanceled = (status) => {
    let newOffset;
    newOffset = offset.CANCELED + LIMIT;
    fetchAPI(newOffset, LIMIT, status, searchKeyPagi.CANCELED).then((data) => {
      setList({ ...list, CANCELED: [...list.CANCELED, ...data.list] });
      setOffset({
        ...offset,
        CANCELED: data.offset
      });
    });
  };

  const handleSearchFinished = (key) => {
    setSearchKeyPagi({
      ...searchKeyPagi,
      FINISHED: key,
    })
    clearTimeout(timeOutSearch);
    timeOutSearch = setTimeout(async () => {
      const finishedList = await fetchAPI(0, LIMIT, STATUS.FINISHED, key);
      setList({
        ...list,
        FINISHED: finishedList.list
      });
      setTotal({
        ...total,
        FINISHED: finishedList.total
      });
    }, 300);
  };

  const handleSearchCanceled = (key) => {
    setSearchKeyPagi({
      ...searchKeyPagi,
      CANCELED: key,
    })
    clearTimeout(timeOutSearch);
    timeOutSearch = setTimeout(async () => {
      const finishedList = await fetchAPI(0, LIMIT, STATUS.CANCELED, key);
      setList({
        ...list,
        CANCELED: finishedList.list
      });
      setTotal({
        ...total,
        CANCELED: finishedList.total
      });
    }, 300);
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  const handleShow = (state) => {

    if (!show[state] && Object.values(show).filter(e => !!e).length >=6) {
      toastr.error("Không được hiển thị quá 6 cột. Vui lòng ẩn các cột khác trước khi hiện cột mới")
    }
    else {
      setShow({ ...show, [state]: !show[state] })
    }
  }
  const renderBySearch = (list) => {

    if (searchKey === "") {
      return list
    }
    const lowserSearchKey = slugify(searchKey).toLowerCase();
    return list.filter(bill => {
      if (bill.id.toLowerCase().includes(searchKey.toLowerCase()) || bill.phone.includes(lowserSearchKey)) {
        return true;
      }
      if (bill.name.split(" ").map(slugify).join(" ").toLowerCase().includes(lowserSearchKey)) {
        return true;
      }
      return false;
    })
  }

  return (
    <div className="list-oder-page">
      <Breadcrumbs title="Đơn hàng" breadcrumbItem="danh sách đơn hàng" />
      <Row>
        {Object.entries(show).filter(([key, state]) => state && !['FINISHED', 'CANCELED'].includes(key)).length ? <Col md={2}>
          <div className="list-item_fieldSearch">
            <input
              className="list-item_input"
              placeholder="Tìm kiếm đơn"
              onChange={(e) => setSearchKey(e.target.value)}
            />
          </div>
        </Col> : null}
        {show.FINISHED ? <Col md={2}>
          <div className="list-item_fieldSearch">
            <input
              className="list-item_input"
              placeholder="Tìm kiếm đơn hoàn thành"
              onChange={(e) => handleSearchFinished(e.target.value)}
            />
          </div>
        </Col> : null}
        {show.CANCELED ? <Col md={2}>
          <div className="list-item_fieldSearch">
            <input
              className="list-item_input"
              placeholder="Tìm kiếm đơn bị huỷ"
              onChange={(e) => handleSearchCanceled(e.target.value)}
            />
          </div>
        </Col> : null}

        <Col>
          <div ref={dropdownRef} className="list-oder-page_wrapDropdown">
            <div
              onClick={() => setShowDropdown(!showDropdown)}
              className={`list-oder-page_buttonDropdown ${showDropdown && "list-oder-page_buttonDropdown-show"
                }`}
            >
              Hiển thị cột
            </div>
            {!isMobile ?
              <div
                className={`list-oder-page_dropdown ${showDropdown && "list-oder-page_dropdown-show"
                  }`}
              >
                {Object.values(STATUS).map( state =>
                  <div className="list-oder-page_field">
                  <label htmlFor={state}>{STATUS_TITLE[state]}</label>
                  <input
                    id={state}
                    checked={show[state]}
                    type="checkbox"
                    onChange={() => handleShow(state)}
                  />
                </div>
                )}
               
              </div>
              : <div
                className={`list-oder-page_dropdown ${showDropdown && "list-oder-page_dropdown-show"
                  }`}
              >
                 {Object.values(STATUS).map( state =>
                  <div className="list-oder-page_field">
                  <label htmlFor={state}>{STATUS_TITLE[state]}</label>
                  <input
                    id={state}
                    checked={show[state]}
                    type="radio"
                    name="radio-status"
                    onClick={() => {
                      setShow({ [state]: true })
                      setShowDropdown(false)
                    }}
                  />
                </div>
                )}
              </div>}
          </div>
        </Col>

      </Row>
      <div className="list-oder-page_table">
        <Row>
          {Object.entries(show).filter(([key, state]) => state && !['FINISHED', 'CANCELED'].includes(key)).map(([key, _]) =>
            <Col md={3} lg={2} sm={12} className="order-status-col">
              <ListItemOder
                status={key}
                title={STATUS_TITLE[key]}
                listData={renderBySearch(list[key])}
                total={renderBySearch(list[key]).length}
              />
            </Col>
          )}
          {show.FINISHED && (
            <Col md={3} lg={2} sm={12} className="order-status-col" >
              <ListItemOder
                status="FINISHED"
                title={STATUS_TITLE.FINISHED}
                listData={list.FINISHED}
                total={total.FINISHED}
                handleShowMore={handleShowMoreFinished}
              />
            </Col>
          )}
          {show.CANCELED && (
            <Col md={3} lg={2} sm={12} className="order-status-col" >
              <ListItemOder
                status="CANCELED"
                title={STATUS_TITLE.CANCELED}
                listData={list.CANCELED}
                total={total.CANCELED}
                handleShowMore={handleShowMoreCanceled}
              />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default ListOder;
