import { useEffect, useState, useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";
import CKEditorComponent from '../../components/CKEditor';

import Select from "react-select";
import slugify from "slugify";
import { uuid, NumberInput } from "../../utils";
import { isMobile } from 'react-device-detect';
import {
  Modal,
  ModalHeader,
  Input,
  Button,
  CardImg,
  CardTitle,
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Badge,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import InfiniteScroll from "react-infinite-scroll-component";
import { get, post } from "../../helpers/api_helper";
import toastr from "toastr";
import DropzoneCustomize from "../../components/Dropzone";
import PicInProduct from "./pic-in-product";
import picUpload from "../../assets/images/assets/upload.png";
import noPic from "../../assets/images/assets/no-images.png";
import {
  AutoSizer,
  List,
  Table,
  Column,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import { WithContext as ReactTags } from "react-tag-input";
import "react-virtualized/styles.css";
import "./index.scss";
import copy from 'copy-to-clipboard';

let clearSearch;
const cache = new CellMeasurerCache({
  fixedWidth: true,
});

const Products = (props) => {
  const history = useHistory();
  const [pagination, setPagination] = useState({
    limit: 30,
    offset: 0,
    total: null,
  });
  const listRef = useRef(null);
  const timeoutSearchMain = useRef(null);
  const [listProducts, setListProducts] = useState([]);
  const [listCategories, setListCategories] = useState([]);
  const [isLoadingListProducts, setIsLoadingListProducts] = useState(false);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [listProductDetails, setListProductDetails] = useState({});
  const [search, setSearch] = useState("");
  const [productPictureUploadId, setProductPictureUploadId] = useState(null);
  const [productAttributePictureUpload, setProductAttributePictureUpload] =
    useState(null);
  const [resultSeachMain, setResultSeachMain] = useState([]);

  const [productPictureId, setProductPictureId] = useState(null);
  const [productDetailEditId, setProductDetailEditId] = useState(null);
  const [productTagEditId, setProductTagEditId] = useState(null);
  const listProductDetailIds = Object.keys(listProductDetails);
  const [indexError, setIndexError] = useState(null);
  const [dragAttribute, setDragAttribute] = useState({
    open: false,
    listAttribute: [],
    productId: "",
  });
  const [promotionAttribute, setPromotionAttribute] = useState(null);
  const [filterSoldOut, setFilterSoldOut] = useState(false);

  const handleDelete = (productId, key, tag) => {
    const temp = { ...listProductDetails };
    if (temp?.[`p-${productId}`]) {
      if (temp[`p-${productId}`][key]) {
        let listTag = temp[`p-${productId}`][key].filter(
          (item, index) => index !== tag
        );
        temp[`p-${productId}`][key] = listTag;
      }
    }
    setListProductDetails(temp);
  };

  const handleAddition = (productId, key, tag) => {
    const temp = { ...listProductDetails };
    if (temp?.[`p-${productId}`]) {
      if (temp[`p-${productId}`][key]) {
        temp[`p-${productId}`][key].push({
          id: tag,
          tag: tag,
        });
      } else {
        temp[`p-${productId}`][key] = [
          {
            id: tag,
            tag: tag,
          },
        ];
      }
    }
    setListProductDetails(temp);
  };

  const formatListProduct = (data, addMore = false) => {
    const { list, total, limit, offset } = data;
    setPagination({
      total,
      limit,
      offset,
    });
    const products = list.map((product) => {
      const { name, picture, id } = product;
      const img = picture.find((p) => p.type === "image");
      return {
        name,
        id,
        picture: img?.url,
      };
    });
    return addMore ? [...listProducts, ...products] : products;
  };

  const onChangeSearch = (e) => {
    const search = e.target.value;
    setSearch(search);
    clearTimeout(clearSearch);
    clearSearch = setTimeout(() => {
      setIsLoadingListProducts(true);
      get('/products/list', {
        params: {
          limit: pagination.limit,
          offset: 0,
          ...(search ? { search } : {}),
          ...(filterSoldOut ? { isSoldOut: filterSoldOut } : {}),
        }
      })
        .then((data) => formatListProduct(data))
        .then((list) => {
          setListProducts(list);
        })
        .finally(() => {
          setIsLoadingListProducts(false);
        });
    }, 300);
  };

  const onChangeFilter = () => {
    const newFilter = !filterSoldOut;
    setFilterSoldOut(newFilter);
    setIsLoadingListProducts(true);
    get('/products/list', {
      params: {
        limit: pagination.limit,
        offset: 0,
        ...(search ? { search } : {}),
        ...(newFilter ? { isSoldOut: newFilter } : {}),
      }
    })
      .then((data) => formatListProduct(data))
      .then((list) => {
        setListProducts(list);
      })
      .finally(() => {
        setIsLoadingListProducts(false);
      });
  };

  const loadMoreProducts = () => {
    setIsLoadingListProducts(true);
    get('/products/list', {
      params: {
        limit: pagination.limit,
        offset: pagination.offset + pagination.limit,
        ...(search ? { search } : {}),
        ...(filterSoldOut ? { isSoldOut: filterSoldOut } : {}),
      }
    })
      .then((data) => formatListProduct(data, true))
      .then((list) => {
        setListProducts(list);
      })
      .finally(() => {
        setIsLoadingListProducts(false);
      });
  };

  useEffect(() => {
    if (isMobile) {
      history.push('/product-list-mobile')
    }
    setIsLoadingListProducts(true);

    get(`/products/list?offset=${pagination.offset}&limit=${pagination.limit}`)
      .then((data) => formatListProduct(data))
      .then((list) => {
        setListProducts(list);
      })
      .finally(() => {
        setIsLoadingListProducts(false);
      });
    get(`/categories/list`).then((data) => {
      setListCategories(
        data.map((cate) => ({
          value: cate.id,
          label: cate.name,
        }))
      );
    });
  }, []);

  const showAllProducts = () => {
    if (pagination.total > 200) {
      return toastr.error(
        "Chỉ cho phép thêm dưới 200 sản phẩm. Vui lòng tìm kiếm chi tiết hơn",
        "Lỗi thêm tất cả"
      );
    }
    setIsAddingProduct(true);
    const listIgnore = listProductDetailIds.join(",");
    get(
      `/products/list-detail?limit=${pagination.total}${search ? `&search=${search}` : ""
      }${listIgnore ? `&ignore=${listIgnore}` : ""}`
    )
      .then((data) => {
        const normalData = {};
        data.forEach((d) => {
          normalData[`p-${d.id}`] = d;
        });
        setListProductDetails({ ...normalData, ...listProductDetails });
        scrollMainProduct(0);
      })
      .finally(() => {
        setIsAddingProduct(false);
      });
  };

  const showProduct = (productId) => {
    setIsAddingProduct(true);
    const listIgnore = listProductDetailIds.join(",");
    if (listIgnore.includes(productId)) {
      return;
    }
    get(`/products/${productId}`)
      .then((data) => {
        setListProductDetails({
          [`p-${data.id}`]: data,
          ...listProductDetails,
        });
        listRef.current.scrollTop = 0;
      })
      .catch(e => {
        toastr.error('Sản phẩm vừa bị xoá')
      })
      .finally(() => {
        setIsAddingProduct(false);
      });
  };

  const closeProduct = (productId) => {
    const temp = { ...listProductDetails };
    delete temp[`p-${productId}`];
    setListProductDetails(temp);
  };

  const deleteProduct = (productId) => {
    const temp = { ...listProductDetails };
    if (temp?.[`p-${productId}`]) {
      temp[`p-${productId}`].isWillDelete = true;
    }
    setListProductDetails(temp);
  };

  const undoDeleteProduct = (productId) => {
    const temp = { ...listProductDetails };
    if (temp?.[`p-${productId}`]) {
      delete temp[`p-${productId}`].isWillDelete;
      setListProductDetails(temp);
    }
  };

  const recoverProduct = (productId) => {
    const temp = { ...listProductDetails };

    if (temp?.[`p-${productId}`]) {
      setIsAddingProduct(true);
      get(`/products/${productId}`)
        .then((data) => {
          temp[`p-${productId}`] = data;
          setListProductDetails(temp);
        })
        .finally(() => {
          setIsAddingProduct(false);
        });
    }
  };

  const onOpenUpload = (productId) => {
    setProductPictureUploadId(productId);
  };

  const onUploadSuccess = (url, type) => {
    const temp = { ...listProductDetails };

    if (temp?.[`p-${productPictureUploadId}`]) {
      const newPicture = {
        url,
        type: (type || "").split("/")?.[0],
      };
      if (!temp?.[`p-${productPictureUploadId}`].picture) {
        temp[`p-${productPictureUploadId}`].picture = [];
      }
      temp?.[`p-${productPictureUploadId}`].picture.push(newPicture);
    }
    setListProductDetails(temp);
  };

  const onUploadSuccessProductAttributePicture = (url, type) => {
    const temp = { ...listProductDetails };
    const { productId, index } = productAttributePictureUpload;
    if (temp?.[`p-${productId}`]?.ProductAttributes?.[index]) {
      temp[`p-${productId}`].ProductAttributes[index].picture = url;
    }
    setListProductDetails(temp);
    setProductAttributePictureUpload(null);
  };

  const onChangeProductAttribute = (productId, index, key, value) => {
    const temp = { ...listProductDetails };
    if (temp?.[`p-${productId}`]?.ProductAttributes?.[index]) {
      temp[`p-${productId}`].ProductAttributes[index][key] = value;
    }
    setListProductDetails(temp);
  };

  const onAddmoreProductAttribute = (productId) => {
    const temp = { ...listProductDetails };
    if (temp?.[`p-${productId}`]) {
      if (!temp?.[`p-${productId}`]?.ProductAttributes) {
        temp[`p-${productId}`].ProductAttributes = [];
      }
      temp[`p-${productId}`].ProductAttributes.push({
        isNew: true,
        active: true,
        id: temp[`p-${productId}`].ProductAttributes.length + 1,
      });
    }
    setListProductDetails(temp);
  };

  const onAddmoreDiscount = (productId) => {
    const temp = { ...listProductDetails };

    if (temp?.[`p-${productId}`]) {
      if (!temp?.[`p-${productId}`]?.discounts) {
        temp[`p-${productId}`].discounts = [];
      }
      const quantityMax = Math.max(...temp[`p-${productId}`].discounts?.map(o => o.quantity), 0)

      temp[`p-${productId}`].discounts.push({
        quantity: quantityMax + 1,
        value: 1,
      });
    }
    setListProductDetails(temp);
  };

  const onChangeDiscounts = (productId, index, key, value) => {
    const temp = { ...listProductDetails };

    if (temp?.[`p-${productId}`]?.discounts?.[index]) {
      temp[`p-${productId}`].discounts[index][key] = value;
    }
    setListProductDetails(temp);
  };

  const onRemoveDiscounts = (productId, index) => {
    const temp = { ...listProductDetails };

    if (temp?.[`p-${productId}`]?.discounts?.[index]) {
      temp?.[`p-${productId}`]?.discounts.splice(index, 1);
    }
    setListProductDetails(temp);
  };

  const onRemoveProductAttribute = (productId, index) => {
    const temp = { ...listProductDetails };
    if (temp?.[`p-${productId}`]?.ProductAttributes?.[index]) {
      if (temp[`p-${productId}`].ProductAttributes[index].isNew) {
        temp[`p-${productId}`].ProductAttributes = temp[
          `p-${productId}`
        ].ProductAttributes.filter((_, key) => key !== index);
      } else {
        temp[`p-${productId}`].ProductAttributes[index].isWillDelete = true;
      }
    }
    setDragAttribute({
      ...dragAttribute,
      listAttribute: temp[`p-${productId}`].ProductAttributes,
    });
    setListProductDetails(temp);
  };

  const onRevertProductAttribute = (productId, index) => {
    const temp = { ...listProductDetails };
    if (temp?.[`p-${productId}`]?.ProductAttributes?.[index]) {
      delete temp[`p-${productId}`].ProductAttributes[index].isWillDelete;
    }
    setListProductDetails(temp);
  };

  const onChangePictureDetail = (productId, pictures) => {
    const temp = { ...listProductDetails };

    if (temp?.[`p-${productId}`]) {
      temp[`p-${productId}`].picture = pictures;
    }
    setListProductDetails(temp);
  };

  const onChangeProductDetail = (productId, value) => {
    const temp = { ...listProductDetails };

    if (temp?.[`p-${productId}`]) {
      if (!temp[`p-${productId}`].ProductDetail) {
        temp[`p-${productId}`].ProductDetail = {};
      }
      temp[`p-${productId}`].ProductDetail.detail = value;
    }
    setListProductDetails(temp);
  };

  const onChangeProduct = (productId, key, value) => {
    const temp = { ...listProductDetails };

    if (temp?.[`p-${productId}`]) {
      temp[`p-${productId}`][key] = value;
    }
    setListProductDetails(temp);
  };

  const handleChangeSoldOut = (productId, soldOut) => {
    const temp = { ...listProductDetails };

    if (temp?.[`p-${productId}`]) {
      temp[`p-${productId}`]["soldOut"] = !soldOut;
    }
    setListProductDetails(temp);
  };

  const rowRender = ({ key, index, style, parent }) => {
    const productId = listProductDetailIds[index];
    const product = listProductDetails[productId];
    return (
      <CellMeasurer cache={cache} key={key} parent={parent} rowIndex={index}>
        <div style={style}>
          <Card className="card-list-product-detail border">
            <CardBody className="p-0">
              <Row>
                <Col className="p-4 pt-2 pb-0">
                  <div className="position-relative ">
                    <div style={{ float: "left", minWidth: 200 }}>
                      <Badge pill className="rounded-pill badge-soft-info">
                        Mã Sp: {product.isNew ? "Tạo mới" : product.id}
                      </Badge>
                      <div>
                        <Select
                          value={
                            product.categoryId
                              ? listCategories.find(
                                (cate) => cate.value === product.categoryId
                              )
                              : null
                          }
                          onChange={(option) =>
                            onChangeProduct(
                              product.id,
                              "categoryId",
                              option?.value
                            )
                          }
                          placeholder="Chọn danh mục"
                          options={listCategories}
                          isClearable
                          classNamePrefix="select2-selection"
                          className={
                            indexError !== null && !product.categoryId
                              ? "placeholder-danger"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div style={{ float: "right", textAlign: "right" }}>
                      {!product.isNew && (
                        <Button
                          className="btn btn-light p-1 border-info bg-white"
                          style={{ marginRight: 20 }}
                          onClick={() => recoverProduct(product.id)}
                        >
                          <i className="text-info fa fa-undo"></i>
                          <strong className="text-info"> Khôi phục</strong>
                        </Button>
                      )}
                      <Button
                        className="btn btn-light p-1 border-warning bg-white"
                        style={{ marginRight: 20 }}
                        onClick={() => closeProduct(product.id)}
                      >
                        <i className="text-warning fa fa-window-close"></i>
                        <strong className="text-warning"> Đóng</strong>
                      </Button>
                      {!product.isNew &&
                        (product.isWillDelete ? (
                          <Button
                            className="btn btn-light p-1 border-primary bg-white"
                            onClick={() => undoDeleteProduct(product.id)}
                          >
                            <i className="text-primary fa fa-trash-restore"></i>
                            <strong className="text-primary"> Huỷ xoá</strong>
                          </Button>
                        ) : (
                          <Button
                            className="btn btn-light p-1 border-danger bg-white"
                            onClick={() => deleteProduct(product.id)}
                          >
                            <i className="text-danger fa fa-trash"></i>
                            <strong className="text-danger"> Xoá</strong>
                          </Button>
                        ))}
                    </div>
                  </div>
                  <div style={{ clear: "both" }}></div>
                </Col>
              </Row>
              <Row className="m-0 product">
                {product.isWillDelete ? <div className="overlay"></div> : null}
                <Col md={6} className="p-3">
                  <Row className="m-0">
                    <Col md={12} className="p-0">
                      <Label className="mb-1">Tên sản phẩm</Label>

                      <Input
                        value={product.name}
                        onChange={(e) =>
                          onChangeProduct(product.id, "name", e.target.value)
                        }
                        className={
                          indexError !== null && !product.name
                            ? "bg-danger"
                            : ""
                        }
                      />
                      <Row>
                        <Col md={3}>
                          <Label className="mb-1">Nặng</Label>
                          <NumberInput
                            onChange={(e) =>
                              onChangeProduct(product.id, "weight", e.value)
                            }
                            customInput={Input}
                            suffix={" g"}
                            value={product.weight}
                            decimalScale={0}
                            className={
                              indexError !== null && !product.weight
                                ? "bg-danger"
                                : ""
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <Label className="mb-1">Dài</Label>
                          <NumberInput
                            onChange={(e) =>
                              onChangeProduct(product.id, "length", e.value)
                            }
                            customInput={Input}
                            suffix={" cm"}
                            value={product.length}
                            decimalScale={0}
                            className={
                              indexError !== null && !product.length
                                ? "bg-danger"
                                : ""
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <Label className="mb-1">Rộng</Label>
                          <NumberInput
                            onChange={(e) =>
                              onChangeProduct(product.id, "width", e.value)
                            }
                            customInput={Input}
                            suffix={" cm"}
                            value={product.width}
                            decimalScale={0}
                            className={
                              indexError !== null && !product.width
                                ? "bg-danger"
                                : ""
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <Label className="mb-1">Cao</Label>
                          <NumberInput
                            onChange={(e) =>
                              onChangeProduct(product.id, "height", e.value)
                            }
                            customInput={Input}
                            suffix={" cm"}
                            value={product.height}
                            decimalScale={0}
                            className={
                              indexError !== null && !product.height
                                ? "bg-danger"
                                : ""
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mt-1 p-0 border">
                      <div
                        style={{ height: "100px", overflowY: "scroll" }}
                        dangerouslySetInnerHTML={{
                          __html: product?.ProductDetail?.detail || "",
                        }}
                        className={`border  p-1 point-click ${indexError && !product?.ProductDetail?.detail
                          ? "bg-danger"
                          : ""
                          }`}
                        onClick={() => setProductDetailEditId(product?.id)}
                      ></div>
                    </Col>
                    <Col
                      md={12}
                      className="mt-1 p-0"
                      style={{ display: "flex" }}
                    >
                      <Button
                        className="btn-info p-1"
                        style={{
                          marginRight: 5,
                          width: "100%",
                          maxWidth: "80px",
                        }}
                        onClick={() => setProductTagEditId(product.id)}
                      >
                        Sửa Tag
                      </Button>
                      <Button
                        style={{
                          marginRight: 5,
                          width: "100%",
                          maxWidth: "110px",
                        }}
                        className={`btn btn-info p-1 ${indexError !== null && !product?.picture?.length
                          ? "btn-danger"
                          : ""
                          }`}
                        onClick={() => setProductPictureId(product.id)}
                      >
                        Hiện {product?.picture?.length || 0} ảnh
                      </Button>{" "}
                      <Button
                        className="btn-info p-1"
                        style={{
                          marginRight: 5,
                          width: "100%",
                          maxWidth: "110px",
                        }}
                        onClick={() => {
                          setPromotionAttribute(product.id);
                        }}
                      >
                        Khuyến mãi
                      </Button>
                      <span
                        className={`${indexError !== null && !product?.picture?.length
                          ? "text-danger"
                          : ""
                          }`}
                      ></span>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <label
                            className="input-group-text"
                            style={{ cursor: "pointer" }}
                          >
                            Hết hàng
                          </label>
                        </div>
                        <div className="input-group-text">
                          <input
                            onChange={() =>
                              handleChangeSoldOut(product.id, !!product.soldOut)
                            }
                            checked={!!product.soldOut}
                            id="soldOut"
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <label
                            className="input-group-text"
                            style={{ cursor: "pointer" }}
                          >
                            Sử dụng
                          </label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className="p-3 list-attribute">
                  <AutoSizer>
                    {({ height, width }) => (
                      <Table
                        width={width}
                        height={height}
                        headerHeight={20}
                        rowHeight={60}
                        rowCount={product?.ProductAttributes?.length || 0}
                        rowGetter={({ index }) =>
                          product.ProductAttributes[index]
                        }
                      >
                        <Column
                          label={
                            <span
                              className={`${indexError !== null &&
                                !product?.ProductAttributes?.length
                                ? "text-danger"
                                : ""
                                }`}
                            >
                              Hình
                            </span>
                          }
                          width={40}
                          dataKey=""
                          cellRenderer={({ rowData: pA, rowIndex }) => (
                            <div className="position-relative picture-attribute">
                              {!pA.isWillDelete && (
                                <img
                                  alt=""
                                  className="image-upload position-absolute"
                                  style={
                                    pA.isWillDelete ? { opacity: 0.2 } : {}
                                  }
                                  width={30}
                                  height={30}
                                  src={picUpload}
                                  onClick={() =>
                                    setProductAttributePictureUpload({
                                      index: rowIndex,
                                      productId: product.id,
                                    })
                                  }
                                />
                              )}
                              <img
                                alt=""
                                width={30}
                                height={30}
                                src={pA.picture || noPic}
                              />
                            </div>
                          )}
                        />
                        <Column
                          label={
                            <span
                              className={`${indexError !== null &&
                                !product?.ProductAttributes?.length
                                ? "text-danger"
                                : ""
                                }`}
                            >
                              {`${product?.ProductAttributes?.length || 0
                                } Thuộc tính`}{" "}
                            </span>
                          }
                          width={200}
                          dataKey=""
                          cellRenderer={({ rowData: pA, rowIndex }) => (
                            <Input
                              readOnly={pA.isWillDelete}
                              style={pA.isWillDelete ? { opacity: 0.2 } : {}}
                              value={pA.attribute || ""}
                              onChange={(e) =>
                                onChangeProductAttribute(
                                  product.id,
                                  rowIndex,
                                  "attribute",
                                  e.target.value
                                )
                              }
                              className={`${indexError !== null && !pA.attribute
                                ? "bg-danger"
                                : ""
                                }`}
                            />
                          )}
                        />
                        <Column
                          label={
                            <span
                              className={`${indexError !== null &&
                                !product?.ProductAttributes?.length
                                ? "text-danger"
                                : ""
                                }`}
                            >
                              Giá bán

                            </span>
                          }
                          width={200}
                          dataKey=""
                          cellRenderer={({ rowData: pA, rowIndex }) => (
                            <NumberInput
                              style={pA.isWillDelete ? { opacity: 0.2 } : {}}
                              readOnly={pA.isWillDelete}
                              className={`${indexError !== null && !pA.price
                                ? "bg-danger"
                                : ""
                                }`}
                              onChange={(e) =>
                                onChangeProductAttribute(
                                  product.id,
                                  rowIndex,
                                  "price",
                                  e.value
                                )
                              }
                              customInput={Input}
                              value={pA.price || 0}
                            />
                          )}
                        />

                        <Column
                          label={
                            <span
                              className={`${indexError !== null &&
                                !product?.ProductAttributes?.length
                                ? "text-danger"
                                : ""
                                }`}
                            >
                              Giá vốn
                            </span>
                          }
                          width={100}
                          dataKey=""
                          cellRenderer={({ rowData: pA, rowIndex }) => (
                            <NumberInput
                              readOnly={pA.isWillDelete}
                              style={pA.isWillDelete ? { opacity: 0.2 } : {}}
                              onChange={(e) =>
                                onChangeProductAttribute(
                                  product.id,
                                  rowIndex,
                                  "cost",
                                  e.value
                                )
                              }
                              customInput={Input}
                              value={pA.cost || 0}
                              suffix={" đ"}
                            />
                          )}
                        />
                        <Column
                          label={
                            <span
                              className={`${indexError !== null &&
                                !product?.ProductAttributes?.length
                                ? "text-danger"
                                : ""
                                }`}
                            >
                              Sử dụng
                            </span>
                          }
                          width={50}
                          dataKey=""
                          cellRenderer={({ rowData: pA, rowIndex }) => (
                            <Input
                              type="checkbox"
                              style={pA.isWillDelete ? { opacity: 0.2 } : {}}
                              readOnly={pA.isWillDelete}

                              onChange={() =>
                                onChangeProductAttribute(
                                  product.id,
                                  rowIndex,
                                  "active",
                                  !pA.active
                                )
                              }
                              customInput={Input}
                              checked={!!pA.active}
                            />
                          )}
                        />
                        <Column
                          label={
                            <>
                              <i
                                className="point-click fa fa-plus text-primary"
                                onClick={() =>
                                  onAddmoreProductAttribute(product.id)
                                }
                              ></i>
                              <i
                                className="point-click fas fa-sort text-primary"
                                style={{ marginLeft: 3 }}
                                onClick={() => {
                                  setDragAttribute({
                                    open: true,
                                    listAttribute: product.ProductAttributes,
                                    productId: product.id,
                                  });
                                }}
                              ></i>
                            </>
                          }
                          width={40}
                          dataKey=""
                          cellRenderer={({ rowData: pA, rowIndex }) =>
                            pA.isWillDelete ? (
                              <i
                                className={`fa fa-undo text-info point-click`}
                                onClick={() =>
                                  onRevertProductAttribute(product.id, rowIndex)
                                }
                              ></i>
                            ) : (
                              <i
                                className={`fa fa-trash text-danger point-click`}
                                onClick={() =>
                                  onRemoveProductAttribute(product.id, rowIndex)
                                }
                              ></i>
                            )
                          }
                        />
                      </Table>
                    )}
                  </AutoSizer>
                  <h5 className="text-center mt-5 text-danger">
                    {indexError !== null && !product?.ProductAttributes?.filter(e => !e.isWillDelete).length
                      ? "Cần ít nhất 1 thuộc tính"
                      : ""}
                  </h5>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </CellMeasurer>
    );
  };

  const onChangeSearchMain = (e) => {
    clearTimeout(timeoutSearchMain.current);
    timeoutSearchMain.current = setTimeout(() => {
      const list = Object.values(listProductDetails)
        .map((product, index) => ({
          name: product.name,
          index,
        }))
        .filter((product) => {
          const slugProduct = slugify(product.name).toLowerCase();
          const slugSearch = slugify(e.target.value).toLowerCase();
          return slugProduct.includes(slugSearch);
        });
      setResultSeachMain(list.slice(0, 5));
    }, 300);
  };

  const scrollMainProduct = (index) => {
    if (listRef?.current) {
      listRef.current.scrollToRow(index);
    }
  };

  const createProduct = () => {
    const id = uuid();
    setListProductDetails({
      [`p-${id}`]: {
        isNew: true,
        id,
        name: "",
        height: 1,
        weight: 1000,
        length: 1,
        width: 1,
        soldOut: false,
        ProductDetail: {
          detail: "",
        },
        ProductAttributes: [
          {
            active: true,
            isNew: true,
          },
        ],
      },
      ...listProductDetails,
    });
    scrollMainProduct(0);
  };

  const saveAllProduct = () => {
    let tempIndexError = null;

    Object.values(listProductDetails).every((product, index) => {
      const {
        name,
        height,
        width,
        length,
        weight,
        ProductAttributes,
        ProductDetail,
        categoryId,
        isWillDelete,
      } = product;
      if (
        !name ||
        !categoryId ||
        !height ||
        !width ||
        !length ||
        !weight ||
        !ProductDetail ||
        !ProductDetail.detail ||
        (
          (!Array.isArray(ProductAttributes) || ProductAttributes.filter(e => !e.isWillDelete).length === 0) && !isWillDelete
        )
      ) {
        tempIndexError = index;
        return false;
      }
      ProductAttributes.every((productAttribute) => {
        const { price, attribute } = productAttribute;
        if (!price || !attribute) {
          tempIndexError = index;
          return false;
        }
      });
      return true;
    });
    if (tempIndexError !== null) {
      setIndexError(tempIndexError);
      scrollMainProduct(tempIndexError);
      toastr.error(
        "Có 1 số sản phẩm chưa điền thông tin. Vui lòng kiểm tra lại"
      );
      return;
    }
    const normalizeData = Object.values(listProductDetails).map(
      ({
        id,
        isNew,
        isWillDelete,
        picture,
        name,
        height,
        width,
        length,
        weight,
        categoryId,
        ProductAttributes,
        ProductDetail,
        ProductTags,
        soldOut,
        discounts
      }) => ({
        id,
        isNew,
        isWillDelete,
        name,
        picture,
        height,
        width,
        length,
        weight,
        discounts: discounts?.sort((a, b) => (b.quantity > a.quantity ? -1 : 1)),
        categoryId,
        ProductDetail: {
          id: ProductDetail.id,
          detail: ProductDetail.detail,
        },
        soldOut,
        ProductAttributes: ProductAttributes.map(
          ({ id, isNew, isWillDelete, attribute, picture, cost, price, active }) => ({
            id,
            isNew,
            isWillDelete,
            attribute,
            picture: picture || undefined,
            cost: cost || undefined,
            price,
            active: !!active
          })
        ),
        ProductTags:
          ProductTags?.length > 0
            ? ProductTags?.map(({ tag }) => tag)
            : undefined,
      })
    );

    post("/products/upsert", {
      products: normalizeData,
    })
      .then(() => {
        toastr.success("Cập nhật thành công");
        setListProductDetails({});
      })
      .catch((err) => {
        toastr.error(err.error);
      });
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let temp = Array.from(dragAttribute.listAttribute);
    const tempListProduct = { ...listProductDetails };
    let [source_data] = temp.splice(e.source.index, 1);
    temp.splice(e.destination.index, 0, source_data);
    if (tempListProduct?.[`p-${dragAttribute.productId}`]) {
      tempListProduct[`p-${dragAttribute.productId}`]["ProductAttributes"] = temp;
    }
    setListProductDetails(tempListProduct);
    setDragAttribute({ ...dragAttribute, listAttribute: temp });
  };
  return (
    <>
      <div className="page-content page-product">
        <Breadcrumbs title="Sản phẩm" breadcrumbItem="danh sách sản phẩm" />
        <Row className="data-content">
          <Col md={2} className="left-list-main">
            <div className="search">
              <input
                type="text"
                className="form-control"
                placeholder="Tìm kiếm"
                onChange={onChangeSearch}
              />
            </div>
            <div className="info-product">
              <div>
                {listProducts.length}/{pagination.total} sp
              </div>
              <div>
                <Label className="mb-0">
                  <Input type="checkbox" value={filterSoldOut} onChange={onChangeFilter} />
                  <span> Lọc hết hàng</span>
                </Label>
              </div>
              {/* <div className="float-right">
                <Button
                  disabled={isAddingProduct || isLoadingListProducts}
                  className="btn-info"
                  onClick={showAllProducts}
                >
                  Thêm tất cả
                </Button>
              </div> */}
            </div>
            <div className="left-list-products" id="scroll-infinite">
              <InfiniteScroll
                scrollableTarget="scroll-infinite"
                dataLength={listProducts.length}
                next={loadMoreProducts}
                hasMore={listProducts.length <= pagination.total}
                loader={<h4>Đang lấy dữ liệu...</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Đã hết sản phẩm</b>
                  </p>
                }
              >
                {listProducts.map((product) => (
                  <Card
                    key={`left-${product.id}`}
                    outline
                    className="border mb-1 point-click"
                    style={{
                      ...(!!listProductDetails[`p-${product.id}`]
                        ? { opacity: 0.2 }
                        : {}),
                    }}
                    onClick={() => {
                      if (!isAddingProduct) {
                        if (!listProductDetails[`p-${product.id}`]) {
                          showProduct(product.id);
                        } else {
                          closeProduct(product.id);
                        }
                      }
                    }}
                  >
                    <Row className="g-0 align-items-center">
                      <Col xs={3}>
                        <CardImg className="img-fluid" src={product.picture} />
                      </Col>
                      <Col xs={9}>
                        <CardBody className="p-1">
                          <CardTitle className="h5 product-name">
                            {product.name}
                          </CardTitle>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </InfiniteScroll>
            </div>
          </Col>
          <Col md={10} className="main-list-product-detail p-1">
            <Row className="m-0 mb-2 search-main">
              <Col md={8} className="p-0">
                {/* <input
                  type="text"
                  className="form-control m-0"
                  placeholder="Tìm kiếm"
                  onChange={onChangeSearchMain}
                  onBlur={() =>
                    setTimeout(() => {
                      setResultSeachMain([]);
                    }, 300)
                  }
                /> */}
              </Col>
              <Col md={4} className="action-right">
                <Button
                  className="btn-info"
                  style={{ marginRight: "10px" }}
                  onClick={createProduct}
                >
                  Tạo sản phẩm
                </Button>
                <Button
                  className="btn-success"
                  onClick={saveAllProduct}
                  disabled={listProductDetailIds.length === 0}
                >
                  Lưu tất cả
                </Button>
              </Col>
              {resultSeachMain.length > 0 ? (
                <div className="result-search-main w-100">
                  <Card className="m-0 border border-primary">
                    <CardBody className="p-1">
                      {resultSeachMain.map((product) => (
                        <div
                          onClick={() => scrollMainProduct(product.index)}
                          className="point-click p-2 result-text"
                          key={product.index}
                        >
                          {product.name}
                        </div>
                      ))}
                    </CardBody>
                  </Card>
                </div>
              ) : null}
            </Row>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  ref={listRef}
                  height={height}
                  width={width}
                  rowCount={listProductDetailIds.length}
                  rowHeight={cache.rowHeight}
                  rowRenderer={rowRender}
                  deferredMeasurementCache={cache}
                />
              )}
            </AutoSizer>
            {listProductDetails.length === 0 ? (
              <div className="no-product-selected">
                <h5>Chọn sản phẩm để tương tác</h5>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>

        <Modal
          isOpen={dragAttribute.open}
          centered={true}
          size={'lg'}
        >
          <>
            <ModalBody style={{ minHeight: 150 }}>
              <DragDropContext onDragEnd={handleDragEnd}>
                <table>
                  <thead>
                    <th>
                      <span
                        className={`${indexError !== null &&
                          !dragAttribute.listAttribute?.length
                          ? "text-danger"
                          : ""
                          }`}
                      >
                        Hình
                      </span>
                    </th>
                    <th>
                      <span
                        className={`${indexError !== null &&
                          !dragAttribute.listAttribute?.length
                          ? "text-danger"
                          : ""
                          }`}
                      >
                        {`${dragAttribute.listAttribute.length || 0
                          } Thuộc tính`}{" "}
                      </span>
                    </th>
                    <th>
                      <span
                        className={`${indexError !== null &&
                          !dragAttribute.listAttribute?.length
                          ? "text-danger"
                          : ""
                          }`}
                      >
                        Giá bán
                      </span>
                    </th>
                    <th>
                      <span
                        className={`${indexError !== null &&
                          !dragAttribute.listAttribute?.length
                          ? "text-danger"
                          : ""
                          }`}
                      >
                        Giá vốn
                      </span>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <i
                        className="point-click fa fa-plus text-primary"
                        onClick={() =>
                          onAddmoreProductAttribute(dragAttribute.productId)
                        }
                      ></i>
                    </th>
                  </thead>
                  <Droppable droppableId="droppable-1">
                    {(provider) => (
                      <tbody
                        className="text-capitalize"
                        ref={provider.innerRef}
                        {...provider.droppableProps}
                      >
                        {dragAttribute?.listAttribute.map((item, index) => (
                          <Draggable
                            key={`key${item.id}`}
                            draggableId={`key${item.id}`}
                            index={index}
                          >
                            {(provider) => (
                              <tr
                                {...provider.draggableProps}
                                ref={provider.innerRef}
                              >
                                <td>
                                  <div className="position-relative picture-attribute">
                                    {!item.isWillDelete && (
                                      <img
                                        className="image-upload position-absolute"
                                        style={
                                          item.isWillDelete
                                            ? { opacity: 0.2 }
                                            : {}
                                        }
                                        width={50}
                                        height={50}
                                        src={picUpload}
                                        onClick={() =>
                                          setProductAttributePictureUpload({
                                            index: index,
                                            productId: dragAttribute.productId,
                                          })
                                        }
                                      />
                                    )}
                                    <img
                                      width={50}
                                      height={50}
                                      src={item.picture || noPic}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <Input
                                    readOnly={item.isWillDelete}
                                    style={
                                      item.isWillDelete ? { opacity: 0.2 } : {}
                                    }
                                    value={item.attribute || ""}
                                    onChange={(e) =>
                                      onChangeProductAttribute(
                                        dragAttribute.productId,
                                        index,
                                        "attribute",
                                        e.target.value
                                      )
                                    }
                                    className={`${indexError !== null && !item.attribute
                                      ? "bg-danger"
                                      : ""
                                      }`}
                                  />
                                </td>
                                <td>
                                  <NumberInput
                                    style={
                                      item.isWillDelete ? { opacity: 0.2 } : {}
                                    }
                                    readOnly={item.isWillDelete}
                                    className={`${indexError !== null && !item.price
                                      ? "bg-danger"
                                      : ""
                                      }`}
                                    onChange={(e) =>
                                      onChangeProductAttribute(
                                        dragAttribute.productId,
                                        index,
                                        "price",
                                        e.value
                                      )
                                    }
                                    customInput={Input}
                                    value={item.price || 0}
                                  />
                                </td>
                                <td>
                                  <NumberInput
                                    readOnly={item.isWillDelete}
                                    style={
                                      item.isWillDelete ? { opacity: 0.2 } : {}
                                    }
                                    onChange={(e) =>
                                      onChangeProductAttribute(
                                        dragAttribute.productId,
                                        index,
                                        "cost",
                                        e.value
                                      )
                                    }
                                    customInput={Input}
                                    value={item.cost || 0}
                                    suffix={" đ"}
                                  />
                                </td>
                                <td>
                                  {item.isWillDelete ? (
                                    <i
                                      className={`fa fa-undo text-info point-click`}
                                      onClick={() =>
                                        onRevertProductAttribute(
                                          dragAttribute.productId,
                                          index
                                        )
                                      }
                                    ></i>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        maxWidth: 50,
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className={`fa fa-trash text-danger point-click`}
                                        onClick={() =>
                                          onRemoveProductAttribute(
                                            dragAttribute.productId,
                                            index
                                          )
                                        }
                                      ></i>
                                      <i
                                        {...provider.dragHandleProps}
                                        style={{ marginLeft: 5 }}
                                        className={`fas fa-bars text-danger point-click`}
                                      ></i>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provider.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </table>
              </DragDropContext>
            </ModalBody>
            <ModalFooter>
              <Button className="btn-secondary" onClick={() => setDragAttribute({ ...dragAttribute, open: false })}>Đóng</Button>
            </ModalFooter>
          </>
        </Modal>
        <Modal
          isOpen={productAttributePictureUpload !== null}
          centered={true}
        >
          {productAttributePictureUpload ? (
            <>
              <ModalHeader toggle={() => setProductAttributePictureUpload(null)}>
                <Badge
                  pill
                  className="rounded-pill badge-soft-info"
                >{`p-${productAttributePictureUpload.productId}`}</Badge>
                <br />
                <small>
                  {
                    listProductDetails[
                      `p-${productAttributePictureUpload.productId}`
                    ]?.name
                  }
                </small>
                <br />
                <small>
                  {
                    listProductDetails[
                      `p-${productAttributePictureUpload.productId}`
                    ]?.ProductAttributes?.[productAttributePictureUpload.index]
                      ?.attribute
                  }
                </small>
              </ModalHeader>
              <ModalBody style={{ minHeight: 150 }}>
                <DropzoneCustomize
                  onUploadSuccess={onUploadSuccessProductAttributePicture}
                  multiple={false}
                />
              </ModalBody>
              <ModalFooter>
                <Button className="btn-secondary" onClick={() => setProductAttributePictureUpload(null)}>Đóng</Button>
              </ModalFooter>
            </>
          ) : null}
        </Modal>

        <Modal
          isOpen={productPictureUploadId !== null}
          centered={true}
        >
          {productPictureUploadId ? (
            <>
              <ModalHeader toggle={() => setProductPictureUploadId(null)}>
                <Badge pill className="rounded-pill badge-soft-info">
                  {productPictureUploadId}
                </Badge>
                <br />
                <small>
                  {listProductDetails[`p-${productPictureUploadId}`]?.name}
                </small>
              </ModalHeader>
              <ModalBody style={{ minHeight: 150 }}>
                <DropzoneCustomize onUploadSuccess={onUploadSuccess} />
              </ModalBody>
              <ModalFooter>
                <Button className="btn-secondary" onClick={() => setProductPictureUploadId(null)}>Đóng</Button>
              </ModalFooter>
            </>
          ) : null}
        </Modal>

        <Modal
          isOpen={productPictureId !== null}
          centered={true}
          className="modal-list-product"
          size="md"
        >
          {productPictureId ? (
            <>
              <ModalHeader toggle={() => setProductPictureId(null)}>
                <Badge pill className="rounded-pill badge-soft-info">
                  {productPictureId}
                </Badge>
                <br />
                <small>
                  {listProductDetails[`p-${productPictureId}`]?.name}
                </small>
              </ModalHeader>
              <ModalBody style={{ minHeight: 150 }}>
                <PicInProduct
                  productId={listProductDetails[`p-${productPictureId}`]?.id}
                  pictures={
                    listProductDetails[`p-${productPictureId}`]?.picture || []
                  }
                  onChange={onChangePictureDetail}
                  onOpenUpload={onOpenUpload}
                />
              </ModalBody>
              <ModalFooter>
                <Button className="btn-secondary" onClick={() => setProductPictureId(null)}>Đóng</Button>
              </ModalFooter>
            </>
          ) : null}
        </Modal>

        <Modal
          isOpen={productDetailEditId !== null}
          centered={true}
          className="modal-list-product"
          size="md"
        >
          {productDetailEditId ? (
            <>
              <ModalHeader toggle={() => setProductDetailEditId(null)}>
                <Badge pill className="rounded-pill badge-soft-info">
                  {productDetailEditId}
                </Badge>
                <br />
                <small>
                  {listProductDetails[`p-${productDetailEditId}`]?.name}
                </small>
              </ModalHeader>
              <ModalBody style={{ minHeight: 450 }}>
                <CKEditorComponent
                  onChange={(e, value) =>
                    onChangeProductDetail(
                      listProductDetails[`p-${productDetailEditId}`].id,
                      value.getData()
                    )
                  }
                  data={
                    listProductDetails[`p-${productDetailEditId}`]
                      ?.ProductDetail?.detail || ""
                  }
                />
              </ModalBody>
              <ModalFooter>
                <Button className="btn-secondary" onClick={() => setProductDetailEditId(null)}>Đóng</Button>
              </ModalFooter>
            </>
          ) : null}
        </Modal>

        <Modal
          isOpen={productTagEditId !== null}
          centered={true}
          className="modal-list-product"
          size="md"
        >
          {productTagEditId ? (
            <>
              <ModalHeader toggle={() => {
                setProductTagEditId(null)
              }}>
                <div>
                  <Badge pill className="rounded-pill badge-soft-info">
                    {productTagEditId}
                  </Badge>
                  <br />
                  <small>
                    {listProductDetails[`p-${productTagEditId}`]?.name}
                  </small>
                </div>

              </ModalHeader>
              <ModalBody style={{ minHeight: 250 }}>
                <Row>
                  <ReactTags
                    handleDelete={(data) =>
                      handleDelete(
                        listProductDetails[`p-${productTagEditId}`]?.id,
                        "ProductTags",
                        data
                      )
                    }
                    handleAddition={(data) =>
                      handleAddition(
                        listProductDetails[`p-${productTagEditId}`]?.id,
                        "ProductTags",
                        data.id
                      )
                    }
                    placeholder="Tag Sản Phẩm"
                    readOnly={false}
                    allowUnique={true}
                    allowDragDrop={false}
                    allowAdditionFromPaste={false}
                    allowDeleteFromEmptyInput={false}
                    tags={listProductDetails[
                      `p-${productTagEditId}`
                    ]?.ProductTags?.map((item) => ({
                      id: item.tag,
                      text: <span onClick={() => {
                        copy(item.tag)
                        toastr.info("Copy tag thành công")
                      }}>{item.tag}</span>,
                    }))}
                  />
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button className="btn-secondary" onClick={() => setProductTagEditId(null)(null)}>Đóng</Button>
              </ModalFooter>
            </>
          ) : null}
        </Modal>

        <Modal
          className="promotion-modal"
          isOpen={!!promotionAttribute}

          centered={true}
          size={'lg'}
          style={{ width: '35%' }}
        >
          <ModalHeader toggle={() => {
            setPromotionAttribute(null)
          }}>
          </ModalHeader>
          <ModalBody toggle={() => setPromotionAttribute(null)} style={{ minHeight: 150 }}>
            <table className="w-100">
              <thead>
                <th><i
                  className="point-click fa fa-plus text-primary"
                  onClick={() =>
                    onAddmoreDiscount(promotionAttribute)
                  }
                ></i></th>
                <th>Số lượng</th>
                <th>Giá giảm</th>
                <th style={{ textAlign: "center" }}>
                </th>
              </thead>
              <tbody>
                {
                  listProductDetails[`p-${promotionAttribute}`]?.discounts?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}. </td>
                      <td>
                        <NumberInput
                          value={item?.quantity || 0}
                          onChange={(e) =>
                            onChangeDiscounts(
                              promotionAttribute,
                              index,
                              "quantity",
                              e.value
                            )
                          }
                          customInput={Input}
                          prefix={">= "}
                          suffix={""}
                        />
                      </td>
                      <td>
                        <NumberInput
                          onChange={(e) =>
                            onChangeDiscounts(
                              promotionAttribute,
                              index,
                              "value",
                              e.value
                            )
                          }
                          customInput={Input}
                          value={item?.value || 0}
                          suffix={" đ"}
                        />
                      </td>
                      <td>
                        <i
                          className={`fa fa-trash text-danger point-click`}
                          onClick={() =>
                            onRemoveDiscounts(
                              promotionAttribute,
                              index
                            )
                          }
                        ></i>
                      </td>
                    </tr>
                  ))
                }

              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <Button className="btn-secondary" onClick={() => setPromotionAttribute(null)}>Đóng</Button>
          </ModalFooter>
        </Modal>

      </div>
    </>
  );
};

export default Products;
