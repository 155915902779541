import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import Select from "react-select";
import { useFormContext, Controller } from "react-hook-form";
import "./ShipInfo.scss";
import LocationProvider from "./providerLocation";
import { get } from "../../helpers/api_helper";

const INVOICE_TYPE_OBJ = {
  DIRECT: 'Hoá đơn bán hàng điện tử',
  E_VAT_INVOICE: 'Hoá đơn giá trị gia tăng điện tử',
  NONE: 'Phiếu giao hàng',
}

const INVOICE_TYPE = Object.entries(INVOICE_TYPE_OBJ).map(([key, value]) => ({
  name: value,
  value: key
}));

const VAT_PERCENTS = [
  {
    name: '10%',
    value: `10`,
  },
  {
    name: '8%',
    value: '8',
  }
]
const ShipInfo = (props) => {
  const { status, idOder } = props;
  const timeoutSearchLastBillByPhone = useRef();
  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const [isShowNoteInvoice, setIsShowNoteInvoice] = useState(!!getValues().invoiceType);
  const [isShowVATPercentInvoice, setIsShowVATPercentInvoice] = useState(getValues().invoiceType === 'E_VAT_INVOICE');
  useEffect(() => {
    setIsShowNoteInvoice(!!getValues().invoiceType)
    setIsShowVATPercentInvoice(getValues().invoiceType === 'E_VAT_INVOICE');
    if (getValues().invoiceType === 'E_VAT_INVOICE' && !getValues().vatPercent) {
      setValue('vatPercent', VAT_PERCENTS[0].value)
    }
  }, [watch('invoiceType')])

  const handleChangePhone = (phone) => {
    if (timeoutSearchLastBillByPhone.current) {
      clearTimeout(timeoutSearchLastBillByPhone.current);
    }

    if (phone?.length >= 10) {
      timeoutSearchLastBillByPhone.current = setTimeout(() => {
        get(`/bills/get-last-info-from-bill-by-phone/${phone}`, {}).then(
          (bill) => {
            if (bill) {
              setValue("name", bill.name);
              setValue("phone", bill.phone);
              setValue("email", bill.email);
              setValue("note", bill.note);
              setValue("invoiceType", bill.invoiceType);
              setValue("vatPercent", bill.vatPercent);
              setValue("noteInvoice", bill.noteInvoice);
              setValue("emailReceivedVAT", bill.emailReceivedVAT);
              if (bill.addressDeliver) {
                setValue('street', bill.addressDeliver.street);
              }
              if (bill.Province) {
                setValue("province", {
                  label: bill.Province.name,
                  value: bill.province,
                });
              }
              if (bill.District) {
                setTimeout(() => {
                  setValue("district", {
                    label: bill.District.name,
                    value: bill.district,
                  });
                }, 1000);
              }
              if (bill.Ward) {
                setTimeout(() => {
                  setValue("ward", {
                    label: bill.Ward.name,
                    value: bill.ward,
                  });
                }, 1000);
              }
            }

            if (bill.userId === null) {
              setValue('customer', {
                label: 'Khách',
                value: null
              })
            } else {
              setValue('customer', {
                label: bill.name,
                value: bill.userId,
              })
            }
          }
        )
          .catch(() => { })
      }, 300)
    }
  };


  // useEffect(() => {
  //   console.log(getValues().district)
  // }, [watch('district')])

  return (
    <div className="ship-info">
      <h5>Thông tin đơn hàng</h5>
      <Row className="mt-3">
        <Col>
          <Row className="mb-3 justify-content-between">
            <label className="col-md-4 col-form-label">Tên người nhận</label>
            <div className="col-md-7">
              <input
                className={`table-product_input ${errors.name ? "table-product_input-error" : ""
                  }`}
                placeholder="Tên người nhận"
                disabled={status === "INFO"}
                {...register("name", {
                  required: "Tên người dùng không được để trống",
                })}
              />
              {errors.name && <p className="p-errors">{errors.name.message}</p>}
            </div>
          </Row>
          <Row className="mb-3 justify-content-between">
            <label className="col-md-4 col-form-label">Số điện thoại</label>
            <div className="col-md-7">
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: "Số điện thoại không được để trống",
                  pattern: {
                    value: /^[0](?:\d){9}$/,
                    message: "Số điện thoại không đúng định dạng",
                  },
                }}
                render={({ field }) => (
                  <>
                    <input
                      disabled={status === "INFO"}
                      className={`table-product_input ${errors.phone ? "table-product_input-error" : ""
                        }`}
                      value={field.value}
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          field.onChange(e);
                          handleChangePhone(e.target.value);
                        }
                      }}
                      placeholder="Số điện thoại"
                    />
                    {errors.phone && (
                      <p className="p-errors">{errors.phone.message}</p>
                    )}
                  </>
                )}
              />
            </div>
          </Row>
          <Row className="mb-3 justify-content-between">
            <label className="col-md-4 col-form-label">Email</label>
            <div className="col-md-7">
              <input
                disabled={status === "INFO"}
                className={`table-product_input ${errors.email ? "table-product_input-error" : ""
                  }`}
                placeholder="Email"
                {...register("email", {
                  required: "Email không được để trống",
                  pattern: {
                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    message: "Email không đúng định dạng",
                  },
                })}
              />
              {errors.email && (
                <p className="p-errors">{errors.email.message}</p>
              )}
            </div>
          </Row>
          {
            !idOder && <Row className="mb-3 justify-content-between">
              <label className="col-md-4 col-form-label">Gửi Email đơn hàng</label>
              <div className="col-md-7">
                <input
                  type="checkbox"
                  style={{ cursor: "pointer" }}
                  disabled={status === "INFO"}
                  placeholder="Gửi email"
                  {...register("isSendMail")}
                />
                {errors.isSendMail && (
                  <p className="p-errors">{errors.isSendMail.message}</p>
                )}
              </div>
            </Row>
          }

          <Row className="justify-content-between">
            <label className="col-md-4 col-form-label">Ghi chú</label>
            <div className="col-md-7">
              <textarea
                style={{ height: '100px' }}
                disabled={status === "INFO"}
                className="table-product_input"
                placeholder="Ghi chú"
                {...register("note")}
              />
            </div>
          </Row>
          <Row className="justify-content-between">
            <label className="col-md-4 col-form-label">Xuất hoá đơn</label>
            <div className="col-md-7">
              {INVOICE_TYPE.map(data =>
                <div key={data.value}>

                  <label><input
                    disabled={status === "INFO"}
                    {...register("invoiceType", {
                      required: 'Vui lòng chọn loại xuất hoá đơn'
                    })}
                    style={{ marginRight: '10px' }}
                    type="radio"
                    value={data.value} />{data.name}</label>
                </div>
              )}
              {errors.invoiceType && (
                <p className="p-errors">{errors.invoiceType.message}</p>
              )}
            </div>
          </Row>

          {isShowVATPercentInvoice
            ? <Row className="justify-content-between">
              <label className="col-md-4 col-form-label">Phần trăm xuất hoá đơn</label>
              <div className="col-md-7">
                {VAT_PERCENTS.map(data =>
                  <div key={data.value}>

                    <label><input
                      disabled={status === "INFO"}
                      {...register("vatPercent", {
                        required: 'Vui lòng chọn giá trị xuất HĐ'
                      })}
                      style={{ marginRight: '10px' }}
                      type="radio"
                      value={data.value} />{data.name}</label>
                  </div>
                )}
                {errors.vatPercent && (
                  <p className="p-errors">{errors.vatPercent.message}</p>
                )}
              </div>
            </Row>
            : null
          }

          {isShowNoteInvoice ?
            <>
              <Row className="justify-content-between">
                <label className="col-md-4 col-form-label">Thông tin hoá đơn</label>
                <div className="col-md-7">
                  <textarea
                    style={{ height: '100px' }}
                    disabled={status === "INFO"}
                    className="table-product_input"
                    placeholder="Thông tin hoá đơn"
                    {...register("noteInvoice")}
                  />
                </div>
              </Row>
              <Row className="justify-content-between">
                <label className="col-md-4 col-form-label">Email nhận hoá đơn</label>
                <div className="col-md-7">
                  <input
                    disabled={status === "INFO"}
                    className="table-product_input"
                    placeholder="Email nhận hoá đơn"
                    {...register("emailReceivedVAT")}
                  />
                </div>
              </Row>
            </>
            : ''}

        </Col>
        <Col>
          <LocationProvider>
            {({ provinceOptions, districtOptions, wardOptions }) => (
              <>
                <Row className="mb-3 justify-content-between">
                  <label className="col-md-4 col-form-label">
                    Thành phố / Tỉnh
                  </label>
                  <div className="col-md-7">
                    <Controller
                      name="province"
                      control={control}
                      rules={{
                        required: "Thành phố / Tỉnh không được để trống",
                      }}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            placeholder="Thành phố / Tỉnh"
                            options={provinceOptions}
                            isDisabled={status === "INFO"}
                            classNamePrefix="select2-selection"
                          />
                          {errors.province && (
                            <p className="p-errors">
                              {errors.province.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  </div>
                </Row>
                <Row className="mb-3 justify-content-between">
                  <label className="col-md-4 col-form-label">
                    Quận / Huyện
                  </label>
                  <div className="col-md-7">
                    <Controller
                      name="district"
                      control={control}
                      rules={{ required: "Quận / Huyện không được để trống" }}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            placeholder="Quận / Huyện"
                            options={districtOptions}
                            isDisabled={status === "INFO"}
                            classNamePrefix="select2-selection"
                          />
                          {errors.district && (
                            <p className="p-errors">
                              {errors.district.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  </div>
                </Row>
                <Row className="mb-3 justify-content-between">
                  <label className="col-md-4 col-form-label">Phường / Xã</label>
                  <div className="col-md-7">
                    <Controller
                      name="ward"
                      control={control}
                      rules={{ required: "Phường / Xã không được để trống" }}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            placeholder="Phường / Xã"
                            options={wardOptions}
                            isDisabled={status === "INFO"}
                            classNamePrefix="select2-selection"
                          />
                          {errors.ward && (
                            <p className="p-errors">{errors.ward.message}</p>
                          )}
                        </>
                      )}
                    />
                  </div>
                </Row>
              </>
            )}
          </LocationProvider>
          <Row className="mb-3 justify-content-between">
            <label className="col-md-4 col-form-label">Địa chỉ cụ thể</label>
            <div className="col-md-7">
              <input
                disabled={status === "INFO"}
                className={`table-product_input ${errors.email ? "table-product_input-error" : ""
                  }`}
                placeholder="Địa chỉ cụ thể"
                {...register("street", {
                  required: "Địa chỉ không được để trống",
                })}
              />
              {errors.street && (
                <p className="p-errors">{errors.street.message}</p>
              )}
            </div>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ShipInfo;
